/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { IncidentLogAwareProcessDto } from '../models/incident-log-aware-process-dto';
import { incidentLogGetById } from '../fn/incident-log/incident-log-get-by-id';
import { IncidentLogGetById$Params } from '../fn/incident-log/incident-log-get-by-id';
import { incidentLogSearch } from '../fn/incident-log/incident-log-search';
import { IncidentLogSearch$Params } from '../fn/incident-log/incident-log-search';
import { PageIncidentLogAwareProcessDto } from '../models/page-incident-log-aware-process-dto';

@Injectable({ providedIn: 'root' })
export class ApiIncidentLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `incidentLogGetById()` */
  static readonly IncidentLogGetByIdPath = '/incident-log/{id}';

  /**
   * Returns an incident log by its ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `incidentLogGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  incidentLogGetById$Response(params: IncidentLogGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<IncidentLogAwareProcessDto>> {
    return incidentLogGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns an incident log by its ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `incidentLogGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  incidentLogGetById(params: IncidentLogGetById$Params, context?: HttpContext): Observable<IncidentLogAwareProcessDto> {
    return this.incidentLogGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<IncidentLogAwareProcessDto>): IncidentLogAwareProcessDto => r.body)
    );
  }

  /** Path part for operation `incidentLogSearch()` */
  static readonly IncidentLogSearchPath = '/incident-log/search-api';

  /**
   * Returns incident logs of background processes according to given predicates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `incidentLogSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  incidentLogSearch$Response(params?: IncidentLogSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageIncidentLogAwareProcessDto>> {
    return incidentLogSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns incident logs of background processes according to given predicates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `incidentLogSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  incidentLogSearch(params?: IncidentLogSearch$Params|null|undefined, context?: HttpContext): Observable<PageIncidentLogAwareProcessDto> {
    return this.incidentLogSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageIncidentLogAwareProcessDto>): PageIncidentLogAwareProcessDto => r.body)
    );
  }

}
